<template>
<div class="mrtg-bg" :style="{ 'background': 'url('+ loginBg + ') no-repeat center center'}">
<div class="mrtg-bg-content">
  <div class="common-btn">
    <a :href="loginLink" target="_blank">
      {{ $t("sign") }}
    </a>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: "MRTG",
  props:{
    loginBg: {
      default() {
        return {}
      }
    },
    loginLink: {
      default() {
        return {}
      }
    },
  },
}
</script>

<style scoped>
.mrtg-bg{
  margin-top: 50px;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*background: url("~@/assets/img/mtrg.png") center center no-repeat;*/
}

.mrtg-bg-content{
  width: 200px;
  height: 120px;
   display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .mrtg-bg{
    margin-top: 20px;
    padding: 30px;
  }
}

</style>