<template>
 <div>
   <div class="page-banner">
     <img v-lazy="banner" class="img-center">
   </div>
   <div class="padding-80" id="inquiry">
     <div class="container">
       <!--    -->
       <div class="inquiry">
         <div class="common-title">
           <h1>{{ $t("menu.inquiry") }}</h1>
           <div class="common-line"></div>
         </div>
         <inquiry-form :inquiryBg="inquiryBg" />
       </div>

      <!--    -->
       <div class="sign padding-t-80"  id="sign">
         <div class="common-title">
           <h1>{{ $t("menu.mrtg") }}</h1>
           <div class="common-line"></div>
         </div>
         <m-r-t-g :loginBg="loginBg" :loginLink="loginLink"/>
        </div>

     <!--    -->
       <div class="address padding-t-80" id="address">
         <div class="common-title">
           <h1>{{ $t("menu.phone") }}</h1>
           <div class="common-line"></div>
         </div>
         <Address :support_phone_numbers="support_phone_numbers"/>
       </div>

     </div>
   </div>

 </div>

</template>

<script>


import inquiryForm from "@/views/supportDetail/inquiryForm";
import MRTG from "@/views/supportDetail/MRTG";
import Address from "@/views/supportDetail/Address";

import {getSupport} from "@/network/main";

export default {
  name: "Support",
  components:{
    inquiryForm,
    MRTG,
    Address,
  },
  data() {
    return{
      banner:{},
      inquiryBg:{},
      loginBg:{},
      loginLink:{},
      support_phone_numbers:[]
    }


  },
  created(){

    //data
    getSupport().then(res => {
      this.banner = res.data.data.banner;
      this.inquiryBg = res.data.data.info.image_one;
      this.loginBg = res.data.data.info.image_two;
      this.loginLink = res.data.data.info.link;
      this.support_phone_numbers = res.data.data.support_phone_numbers;
    })

  },
  mounted() {
    let selectId = localStorage.getItem("toId");
    let toElement = document.querySelector(selectId);
    //如果对应id存在，就跳转
    // console.log(selectId,toElement)
    if (toElement !== null) {
      //console.log(toElement, "toElement");
      setTimeout(function() {
        toElement.scrollIntoView({
          behavior: "smooth"
        });

      }, 1000);

    }
  },
  methods: {

  },
  deactivated() {
    localStorage.setItem("toId", "");
  },
}
</script>

<style scoped>

</style>